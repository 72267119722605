import React, { Component } from 'react'
import SectionTitleOne from './SectionTitleOne'
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

class GalleryImage extends Component {
    render() {
            var img1 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage01.jpg');
            var img2 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage02.jpg');
            var img3 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage03.jpg');
            var img4 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage04.jpg');
            var img5 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage05.jpg');
            var img6 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage06.jpg');
            var img7 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage07.jpg');
            var img8 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage08.jpg');
            var img9 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage09.jpg');
            var img10 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage10.jpg');
            var img11 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage11.jpg');
            var img12 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage12.jpg');
            var img13 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage13.jpg');
            var img14 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage14.jpg');
            var img15 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage15.jpg');
            var img16 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage16.jpg');
            var img17 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage17.jpg');
            var img18 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage18.jpg');

        return (
            <section className="gallery-one-wrapper section-bg text-white section-padding vSleepDisorderBlock">
                <div className="hero-shape service-shape">
                    <img src={require("../assets/img/shape/rmc.png")} alt="donto" className="shape shape2" />
                    <img src={require("../assets/img/shape/plusgs.png")} alt="donto" className="shape shape4" />
                    <img src={require("../assets/img/shape/plusg.png")} alt="donto" className="shape shape6" />
                    <img src={require("../assets/img/shape/plusr.png")} alt="donto" className="shape shape8" />
                    <img src={require("../assets/img/shape/sgdot.png")} alt="donto" className="shape shape9" />
                    <img src={require("../assets/img/shape/xsrdot.png")} alt="donto" className="shape shape10" />
                    <img src={require("../assets/img/shape/rmc.png")} alt="donto" className="shape s1" />
                    <img src={require("../assets/img/shape/plusgs.png")} alt="donto" className="shape s2" />
                    <img src={require("../assets/img/shape/sgdot.png")} alt="donto" className="shape s3" />
                </div>
                <div className="container">

                    <SectionTitleOne BigTitle="A Look at Our World" />

                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img1}
                                    image={img1} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img2}
                                    image={img2} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img3}
                                    image={img3} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img4}
                                    image={img4} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img5}
                                    image={img5} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img6}
                                    image={img6} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img7}
                                    image={img8} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img8}
                                    image={img8} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img9}
                                    image={img9} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img10}
                                    image={img10} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img11}
                                    image={img11} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img12}
                                    image={img12} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img13}
                                    image={img13} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img14}
                                    image={img15} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img15}
                                    image={img15} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img16}
                                    image={img16} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img17}
                                    image={img17} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img18}
                                    image={img18} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default GalleryImage
