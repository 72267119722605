const BlogData = {
    postItem: [ 
        {
            id: 1,
            imgUrl: "https://sleeptherapeutics.in/blog/wp-content/uploads/2023/08/Sleep-Therapeutics-_-August-Blog-01-1568x882.jpg",
            title: "How does sleep affect Mental processes and Behaviour?",
           // author: "Sleeptherapeutics",
            date: "23 Aug, 2023",
            link: "https://sleeptherapeutics.in/blog/how-does-sleep-affect-mental-processes-and-behaviour/",
        },
        {
            id: 2,
            imgUrl: "https://sleeptherapeutics.in/blog/wp-content/uploads/2023/08/Sleep-Therapeutics-_-August-Blog-02-1568x882.jpg",
            title: "Understanding Sleep Apnea Causes and Risk Factors",
           // author: "Sleeptherapeutics",
            date: "22 Aug, 2023",
            link: "https://sleeptherapeutics.in/blog/understanding-sleep-apnea-causes-risk-factors/",
        },
        {
            id: 3,
            imgUrl: "https://sleeptherapeutics.in/blog/wp-content/uploads/2023/08/Sleep-Therapeutics-_-August-Blog.jpg",
            title: "Diagnosis and Treatment of Sleep Snoring",
           // author: "Sleeptherapeutics",
            date: "19 Aug, 2023",
            link: "https://sleeptherapeutics.in/blog/sleep-blogs/treatment-sleep-snoring/",
        },
        {/*{
            id: 4,
            imgUrl: "blog2.jpg",
            title: "Maximize Your 2019 Dental Insurance with lots of Benefits",
            author: "Shahin",
            date: "12 Oct, 2019",
            link: "/Page/BlogDetails",
        },
        {
            id: 5,
            imgUrl: "blog3.jpg",
            title: "Are Your Teeth Making You Old? You Surprised!",
            author: "Admin",
            date: "12 Oct, 2019",
            link: ".#",
        },
        {
            id: 6,
            imgUrl: "blog1.jpg",
            title: "Maximize Your 2019 Dental Insurance with lots of Benefits",
            author: "Shahin",
            date: "12 Oct, 2019",
            link: ".#",
        }*/}
    ],

}


export default BlogData
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// const BlogData = () => {
//     const [blogData, setBlogDate] = useState();
//     const getBlogDate = () => {
//         axios.get('http://sleeptherapeutics.in/blog/wp-json/wp/v2/posts')
//         .then(res=>setBlogDate(res.data));
//     }
//     useEffect(()=>{
//         getBlogDate()
//     },[])
//     return(
//         <div>{blogData}</div>
//     )
// }

// export default BlogData;