import React, { Component } from 'react'
import Slider from 'react-slick';

class Qulification extends Component {
    render() {

        const settings = {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]           
        };


       



        return (
            <section className="brand-logo-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider className="brand-logo-carousel" {...settings}>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/01.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/02.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/03.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/04.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/05.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/06.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/07.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/08.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/09.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/10.png")} alt="donto" />
                                </div>
                                <div className="single-brand-logo vAwardsImg">
                                    <img src={require("../assets/img/whatsinaname/Qualification/11.png")} alt="donto" />
                                </div>
                            </Slider >
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Qulification
