import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerNotFound from './BannerNotFound'
import SectionTitleOne from './SectionTitleOne'
import ContactAppointment from './ContactAppointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';

class PageNotfound extends Component {
    render() {
        return (
            <React.Fragment>
                <BannerNotFound pageTitle='Page Not Found' />
            </React.Fragment>
        )
    }
}

export default PageNotfound
