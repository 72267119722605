import React, { Component } from 'react'
import {Link, NavLink} from 'react-router-dom';


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          scroll: false,
        }
      }
    //   componentDidMount(){
       
    //      () => {
    //         this.scroll.addEventListener("scroll", () => {
    //             let activeClass = 'sticky';
    //      if(window.scrollY === 0){
    //          activeClass = 'top';
    //      }
    //      this.setState({ activeClass });
    //         });
             
            
    //      };
    //  }

 

    componentDidMount(){
       window.addEventListener("scroll", () => {
                let activeClass = 'sticky';
         if(window.scrollY === 0){
            activeClass = 'top';
         }
        this.setState({ activeClass });
             });
             
    }

    // handleClick = (divNum) =>{
    //    alert(divNum)
    //    // this.setState(this.state.activeNum)
    //   }
    //   checkScroll = e => {
    //     alert("hello")
    //     const bar = document.getElementById("bar");
    //     // get bar since it offsetTop might not be fixed
    
    //     //console.log(window.scrollY, bar.offsetTop);
    //     // why loging these two? you gonna see
    //     //console.log(scroll);
    //     if (window.scrollY >= 90) {
    //       //      if (window.scrollY + 10 >= bar.offsetTop)
    //       // why +10 ?? will see
    //       this.setState(true);
    //     } else {
    //       this.setState(false);
    //     }
    // }

    
    
    
     componentWillUnmount() { this.scroll.document.removeEventListener("scroll", () => {
                this.setState(false);
            }); } 
    render() {
        //const {activeNum} = this.state
        //var isActive = this.context.router.route.location.pathname === this.props.to;
        //var className = isActive ? 'vActive' : '';
        return (
            // className={`header-one ${this.state.scroll ? 'sticky' : ''}`} id="bar"
            <header className={`header-one ${this.state.activeClass}`} id="bar">
                <div className="main-menu">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-lg-3 d-flex col-5">
                                <Link className="navbar-brand logo" to='/'>
                                    <img src={require("../../assets/img/whatsinaname/Headerlogo.png") } alt="donto" />
                                </Link>
                            </div>
                            <div className="col-lg-9 col-md-9 d-none d-lg-block text-lg-right">
                                <nav id="responsive-menu" className="menu-style-one">
                                    <ul className="menu-items">
                                        <li><NavLink to='/'>home</NavLink></li>
                                        <li><span className='vCusMenu'>About us</span>
                                            <ul>
                                                <li><NavLink to='/AboutUs' activeClassName="activeMenu">About us</NavLink></li>
                                                <li><NavLink to='/NewsEvents' activeClassName="activeMenu">News / Events</NavLink></li>
                                                <li><NavLink to='/BreatheClinic' activeClassName="activeMenu">Breathe Clinic</NavLink></li>
                                                <li><NavLink to='/VirtualTour' activeClassName="activeMenu">360<sup>0</sup> &nbsp;Virtual Tour</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><span  className='vCusMenu'>Our Sleep Solution</span>
                                            <ul>
                                                <li><NavLink to='/TreatmentPackages' activeClassName="activeMenu">Treatment Packages</NavLink></li>
                                                <li><NavLink to='/Services' activeClassName="activeMenu">Services</NavLink></li>
                                            </ul>
                                        </li>
                                        {/*<li><Link to='/Page/DentistDetails'>Partner with us</Link></li>*/}
                                        <li><NavLink to='/PartnerWithUs' activeClassName="activeMenu">Partner with us</NavLink></li>
                                        <li><a href='https://sleeptherapeutics.in/blog/'>Blog</a></li>
                                        <li><NavLink to='/Contact' activeClassName="activeMenu">Contact</NavLink></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-md-9 col-sm-7  col-6 d-block d-lg-none vMenuBar">
                                <nav className="navbar navbar-expand-lg text-right navbar-light mobile-nav">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobilenav">
                                        <span className="fal fa-bars" />
                                    </button>
                                </nav>
                            </div>
                            <div className="collapse navbar-collapse mobile-menu" id="mobilenav">
                                <ul className="navbar-nav vMobAdjest">
                                    <li data-toggle="collapse" data-target=".navbar-collapse">
                                        <NavLink activeClassName="activeMenu" exact to='/'>Home</NavLink>
                                    </li>
                                    <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink activeClassName="activeMenu" to='/AboutUs' eventKey="1">About us</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/NewsEvents'  activeClassName="activeMenu">News / Events</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/BreatheClinic'>Breathe Clinic</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/VirtualTour'>360<sup>0</sup> Virtual Tour</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/TreatmentPackages'>Treatment Packages</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/Services'>Services</NavLink></li>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/PartnerWithUs'>Partner with us</NavLink></li>
                                                <a href='https://sleeptherapeutics.in/blog/'>Blog</a>
                                                <li data-toggle="collapse" data-target=".navbar-collapse"><NavLink to='/Contact'>Contact</NavLink></li>
                                    {/* <li className="nav-item">
                                        *<span className="vCusMenu">Our Sleep Solution</span>*
                                        <ul>
                                            <li><NavLink to='/TreatmentPackages'>Treatment Packages</NavLink></li>
                                            <li><NavLink to='/Services'>Services</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to='/PartnerWithUs'>Partner with us</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href='https://sleeptherapeutics.in/blog/'>Blog</a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to='/Contact'>Contact</NavLink>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>            
        )
    }
}

export default Navbar
