import axios from 'axios';
import React, { Component } from 'react'

class BlogCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        };
    }
    componentDidMount(){
        axios.get('http://sleeptherapeutics.in/blog/wp-json/wp/v2/posts')
        .then(res=>{
            this.setState({posts:res.data.posts});
            console.log(this.state.posts);
        })
    }
    render() {

        let BlogData = this.props.BlogData;

        return (
            <React.Fragment>
                {
                    BlogData.postItem.map(item => {
                        return (
                            <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12" key={item.id}>
                                <div className="single-blog-card" >
                                    <div className="featured-thumb">
                                        {/* <img src={require("../../assets/img/whatsinaname/" + item.imgUrl)} alt="blog"/> */}
                                        <img src={item.imgUrl} alt="blog" />
                                    </div>
                                    <div className="card-content">
                                        <h3><a href={item.link} >{item.title}</a></h3>
                                        <div className="card-meta">
                                            {/* <div className="col-6">
                                                <span>Post by: {item.author}</span>
                                            </div> */}
                                            <div className="col-6">
                                                <span>{item.date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }                
            </React.Fragment>
        )
    }
}

export default BlogCard
