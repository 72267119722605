import React, { Component } from 'react'
import Slider from 'react-slick';

class SleepNews extends Component {
    render() {

        const settings = {
            slidesToShow: 3,
            slidesToScroll: 4,
            dots: true,
            infinite: true,
            autoplay: false,
            speed: 4000,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]           
        };

        return (
            <section className="brand-logo-wrapper vVedioBlock">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12 text-center'>
                            <h1>Our Latest News</h1>
                        </div>
                        <div className="col-md-12">
                            <Slider className="brand-logo-carousel" {...settings}>
                                <div className="single-brand-logo videoSpace">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/4pzf1Utze5E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    {/*<img src={require("../assets/img/brand/2.png")} alt="donto" />*/}
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Gmd1SgU8VMI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/HFxbW6Hr9WU?si=8mZDLFtgpzSzM_0Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9OHAZEBKNjM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ApfYTkiaM_c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/yCiGu-WOL2s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/J94Z40h52Ns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </Slider >
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SleepNews
