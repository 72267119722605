import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerBreathe from './BannerBreathe'
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'

import { Helmet } from 'react-helmet';

class BreatheClinic extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Top Pulmonologist in Hyderabad - Dr. Harshini Errabelli | The Breathe Clinic</title>
                    <meta name='description' content='Meet Dr. Harshini Errabelli, One of the top pulmonologist in Hyderabad. Experience expert respiratory care at The Breathe Clinic.' />
                </Helmet>
                <BannerBreathe pageTitle='Breathe Clinic' />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>Hyderabad’s Exclusive Pulmonology Clinic</h1>
                                    <p>We offer specialised services in allergies, smoking de-addiction, ILD, lung health, lung infection and lung cancer. Dr. Harshini Errabelli, an acclaimed interventional pulmonologist with extensive qualifications and international experience, heads The Breathe Clinic.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="patient-focused-wrap section-padding text-white section-bg text-center vBlue315">
                    <div className="container">
                        
                        <SectionTitleOne BigTitle='Our Unique Approach' />

                        <div className="row equal-cols">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BAllergyClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Allergy Clinic</a></h3>
                                    <p>We specialise in diagnosing and treating allergic lung diseases, including Asthma, COPD, and Bronchiectasis. Common symptoms of these conditions include cough, breathlessness, sneezing, chest pain, and wheezing. Our diagnostic methods include spirometry, certain blood investigations and chest radiography. We provide personalised treatments based on individual needs.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BSmokingDeaddictionClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Smoking De-addiction Clinic</a></h3>
                                    <p>Our pulmonologist, counsellor, and psychiatrist work together to help patients quit smoking in a phase-wise manner. We strive to make the process as comfortable as possible and minimise any side effects of quitting smoking. Nicotine gums and patches may be used to help patients in the initial stages of treatment.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BILDClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">ILD Clinic</a></h3>
                                    <p>We specialise in treating patients with Interstitial Lung Diseases. We diagnose these patients using chest radiography, spirometry, and blood investigations and treat them with medications, lung rehabilitation and oxygen therapy.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BLungHealthClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Lung Health Clinic</a></h3>
                                    <p>We offer general lung health checkups and treatment for minor to major lung disorders, along with counselling.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BLungInfectionClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Lung Infection Clinic</a></h3>
                                    <p>At this clinic, we focus on diagnosing upper respiratory tract infections, tuberculosis, bacterial, viral, or fungal pneumonia, COVID-19, influenza-related infections, and post-infection complications. Patients typically present with symptoms such as cough, sputum, breathlessness, fever, generalised weakness and chest pain. We utilise chest radiography, sputum analysis or throat swab, and relevant blood investigations to diagnose and treat these conditions.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/Icons/BLungCancerClinic.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Lung Cancer Clinic</a></h3>
                                    <p>We diagnose and treat patients with lung cancer. Symptoms typically include cough, blood in sputum, or chest pain. We use chest radiography to identify any lung cancer, and our Interventional Pulmonologist performs Bronchoscopy guided biopsies, thoracoscopy, and EBUS-guided FNAC to identify the type of cancer. We also provide treatments for complications of cancer, such as airway fistulas, tracheal and bronchial stenosis, and malignant pleural effusions.</p>
                                    <p>Interventions like tracheal and bronchial stenting under rigid bronchoscopy, therapeutic thoracoscopy, talc pleurodesis, and Intercoastal drains are performed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='doctors-wrapper vOurInt section-padding'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='vOurFactHeads text-center'>
                                            <div>
                                                <div className="vOurImg">
                                                    <img src={require ("../assets/img/whatsinaname/ourservice/OPDFacility.png") } alt="" />
                                                </div>
                                                <p>OPD Facility</p>
                                            </div>
                                            <div>
                                                <div className="vOurImg">
                                                    <img src={require ("../assets/img/whatsinaname/ourservice/SpirometryLab.png") } alt="" />
                                                </div>
                                                <p>Spirometry Lab</p>
                                            </div>
                                            <div>
                                                <div className="vOurImg">
                                                    <img src={require ("../assets/img/whatsinaname/ourservice/BloodsInvestigations.png") } alt="" />
                                                </div>
                                                <p>Bloods Investigations</p>
                                            </div>
                                            <div>
                                                <div className="vOurImg">
                                                    <img src={require ("../assets/img/whatsinaname/ourservice/Pharmacy.png") } alt="" />
                                                </div>
                                                <p>Pharmacy</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="promo-content-section promo-bg section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row  align-items-center'>
                                <div className='col-12 section-title-one text-center'>
                                    <h1>Our Interventional Pulmonologist</h1>
                                </div>
                                <div className="col-lg-6 col-12">
                                <img src={require("../assets/img/whatsinaname/DoctorLanding.jpg")} alt="" />
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Dr. Harshini Errabelli</h2>
                                    <p>Founder & Director<br/>Interventional Pulmonologist and Sleep Specialist</p>
                                    <p>MD (Respiratory, Critical Care & Sleep Medicine) <br/>
Fellowship in Interventional Pulmonology (Malaysia)<br/>
II Level Master in Interventional Pulmonology (Italy)
</p>
                                </div>
                            </div>
                            <div className='clearfix'></div>
                            <div className='col-12'>
                                <div className='vOurSpeciFeatre'>
                                    <ul>
                                        <li>Fellowship in Interventional Pulmonology from institutes in Hyderabad & Malaysia</li>
                                        <li>2nd Level Master in Interventional Pulmonology from the University of Florence, Italy. One of the first batch of Indians to get this fellowship</li>
                                        <li>Trained in Sleep Medicine</li>
                                        <li>Member of Indian Chest Society, National College of Chest Physicians, European Respiratory Society, Malaysian Association of Bronchology and Interventional Pulmonology</li>
                                        <li>Member of the Indian Society of Sleep Research</li>
                                        <li>Member of the American Academy of Sleep Medicine</li>
                                        <li>Member of World Sleep Society</li>
                                        <li>Performed the first EBUS-guided FNAC and gave spot diagnosis in Warangal, Telangana, in November 2022</li>
                                        <li>Faculty in CHEST 2022, International American Pulmonology Conference conducted in Bologna, Italy</li>
                                        <li>Faculty in Bronchus, International Interventional Pulmonology conference conducted in Hyderabad, India in Feb 2023</li>
                                        <li>Faculty in TSTCCON conducted in Karimnagar, Telangana, India in Feb 2023</li>
                                        <li>Faculty in various other conferences in Hyderabad.</li>
                                    </ul>
                                </div>
                                <div className='vOurSpeciFeatre'>
                                    <h2>Interventions Carried Out by Dr. Harshini Errabelli</h2>
                                    <p>Dr. Harshini has a wide range of experience in the field of pulmonology. She has performed numerous interventions during her career in respiratory medicine. These include:</p>
                                    <ul>
                                        <li>Argon plasma coagulation</li>
                                        <li>Balloon dilation</li>
                                        <li>Bronchoalveolar lavage</li>
                                        <li>Bronchoscopy</li>
                                        <li>Pleuroscopy</li>
                                        <li>Rigid bronchoscopy</li>
                                        <li>Thoracentesis</li>
                                        <li>Foreign body removal</li>
                                        <li>Transbronchial cryo-biopsy for diagnosis of interstitial lung disease</li>
                                        <li>Endobronchial cryotherapy and biopsy</li>
                                        <li>Endobronchial ultrasound biopsy</li>
                                        <li>Placement of stents in the airways</li>
                                        <li>Radial endobronchial ultrasound biopsy</li>
                                    </ul>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                {/*<BlogOne BlogData={BlogData} />
                <Appointment appointmentTitle={"Seeking solutions to Respiratory Disorders?"} subTitle={"We’re a message away."}/>*/}

            </React.Fragment>
        )
    }
}

export default BreatheClinic
