import React, { Component } from 'react'
import Slider from 'react-slick';

class SleepDisorder extends Component {
    render() {

        const settings = {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]           
        };


       



        return (
            <section className="brand-logo-wrapper section-padding vSleepDisorderBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Sleep Disorder Symptoms</h2>
                            <Slider className="brand-logo-carousel vSleepOrderList" {...settings}>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/Snoring.png")} alt="donto" />
                                        <h3>Snoring</h3>
                                        <p>Regular and loud snoring may indicate an underlying disorder.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/Sleeplessness.png")} alt="donto" />
                                        <h3>Sleeplessness</h3>
                                        <p>Also known as Insomnia, it saps your energy and keeps your attention level ‘low.’</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/Obesity.png")} alt="donto" />
                                        <h3>Obesity</h3>
                                        <p>An unexpected weight gain may occur with sleep deprivation or poor sleeping habits.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/Breathlessness.png")} alt="donto" />
                                        <h3>Breathlessness</h3>
                                        <p>Shortness of breath is observed in people having very poor sleep hygiene.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/Fatigue.png")} alt="donto" />
                                        <h3>Fatigue</h3>
                                        <p>A lack of energy or zero interest in daily activities has been associated with poor sleep quality.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/ConsistentNight.png")} alt="donto" />
                                        <h3>Night Awakenings</h3>
                                        <p>Bad sleep habits, stress, anxiety, depression, sleep apnea or other reasons could be behind repeated night awakenings.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/PersistentInability.png")} alt="donto" />
                                        <h3>Chronic Insomnia</h3>
                                        <p>Chronic Insomnia that stress, anxiety, poor sleep habits, work pressure or other factors could cause.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/SleepinessDuringDriving.png")} alt="donto" />
                                        <h3>Sleepiness During Driving</h3>
                                        <p>Poor sleep hygiene contributes to many motor accidents every year, apart from affecting other activities.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/DaytimeSleepiness.png")} alt="donto" />
                                        <h3>Daytime Sleepiness</h3>
                                        <p>A person may feel sleepy despite getting adequate sleep due to changed circadian rhythms, sleep apnea, narcolepsy, etc.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/RestlessLeg.png")} alt="donto" />
                                        <h3>Restless Legs Syndrome</h3>
                                        <p>Repeated leg movement may happen at night due to sleep issues reported by parents or partners.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/LackConcentartion.png")} alt="donto" />
                                        <h3>Low Work Concentration</h3>
                                        <p>Lack of quality sleep affects a person’s concentration at work very much.</p>
                                    </div>
                                </div>
                                <div className="single-brand-logo">
                                    <div className='sleepdisorder'>
                                        <img src={require("../assets/img/whatsinaname/sleepdisorder/AnyAbnormalSleep.png")} alt="donto" />
                                        <h3>Unusual Sleep Symptoms</h3>
                                        <p>Any other abnormal sleep symptoms call for closer introspection.</p>
                                    </div>
                                </div>
                            </Slider >
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SleepDisorder
