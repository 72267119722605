import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerTreatment from './BannerTreatment'
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';

import { Helmet } from 'react-helmet';

class TreatmentPackages extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Disorder Treatment In Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Explore advanced Sleep Disorder Treatment in Hyderabad at our Sleep Centre. Our specialists provide tailored plans for various sleep conditions.' />
                </Helmet>
                <BannerTreatment pageTitle='Treatment Packages' />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <p>At Sleep Therapeutics, we have some of the best treatment packages in the field of sleep medicine. Our sleep specialists tailor the treatment packages per each patient's needs following their diagnosis. Thus, each patient receives tailored care and support throughout their treatment journey. The treatments could last three to twelve months, depending on the condition.</p>
                                    <p>Our commitment to each patient is reflected in the words of our Founder, Dr. Harshini Errabelli.</p>
                                    <p>“Our priorities are patient time, comfort, safety, privacy, and treatment compliance.”</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                

                <section className="promo-content-section promo-bg section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row  align-items-center'>
                                <div className='col-lg-12 text-center'>
                            <div className="section-title-one">
                                <h1>Here are our treatment packages:</h1>
                            </div>
                        </div>
                                <div className='clearfix'></div>
                                <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Insomnia Pack</h2>
                                    <p className='vTreatSubText'>For individuals suffering from insomnia:</p>
                                    <div className='vTreatmentPcage'>
                                        <ul>
                                            <li>Includes consultations with sleep specialists & other doctors, sleep tracking, dietitian, fitness coaches, and lifestyle mentor consultations</li>
                                            <li>Provision of Sleep Kits and trackers to monitor sleep hygiene</li>
                                            <li>Access to various fitness programs</li>
                                            <li>Cognitive Behaviour Therapy</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#consult" class="theme-btn fill-btn vBlue-btn">ENQUIRE NOW</a>
                                    </div>
                                </div>
                            </div>
                                <div className="col-lg-6 col-12 order-xs-1 vPB30">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/InsomniaPack1.png")} alt="" />
                            </div>
                            <div className='clearfix'></div>
                                <div className="col-lg-6 col-12 vPB30 vDesktop">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/OSAPack2.png")} alt="" />
                            </div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>OSA (Obstructive Sleep Apnea) Pack</h2>
                                    <p className='vTreatSubText'>For individuals suffering from Obstructive Sleep Apnea (OSA):</p>
                                    <div className='vTreatmentPcage'>
                                        <ul>
                                            <li>Includes consultations with sleep specialists, fitness coaches, health coaches & other doctors</li>
                                            <li>Sleep Study</li>
                                            <li>Personalised diet plan</li>
                                            <li>Exercise prescription</li>
                                            <li>Monitoring of CPAP compliance & service of CPAP machine</li>
                                            <li>Access to various fitness programs</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#consult" class="theme-btn fill-btn vBlue-btn">ENQUIRE NOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vMob">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/OSAPack2.png")} alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>General Sleep Checkup</h2>
                                    <p className='vTreatSubText'>For anyone who wants to know about their sleep health and the effect of sleep on their overall health.</p>
                                    <div className='vTreatmentPcage'>
                                        <ul>
                                            <li>Includes two sleep consultations, sleep testing and reporting</li>
                                            <li>Provision of a sleep kit and education about sleep hygiene</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#consult" class="theme-btn fill-btn vBlue-btn">ENQUIRE NOW</a>
                                    </div>
                                </div>
                            </div>
                                <div className="col-lg-6 col-12 vPB30">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/GeneralSleepCheckup.png")} alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30 vDesktop">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/HealthySleepPack3.png")} alt="" />
                            </div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>My Healthy Sleep Pack</h2>
                                    <p className='vTreatSubText'>For kids, adolescents & adults who have difficulty sleeping on time and wish to improve their sleep quality:</p>
                                    <div className='vTreatmentPcage'>
                                        <ul>
                                            <li>Includes consultations with sleep specialists and health coach</li>
                                            <li>Educative sessions on sleep hygiene & consultations to improve sleep</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#consult" class="theme-btn fill-btn vBlue-btn">ENQUIRE NOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vMob">
                                <img src={require("../assets/img/whatsinaname/treamentpackages/HealthySleepPack3.png")} alt="" />
                            </div>
                            <div className='clearfix'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                <BlogOne BlogData={BlogData} />
                <Appointment appointmentTitle={"Book a Consultation"} />

            </React.Fragment>
        )
    }
}

export default TreatmentPackages
