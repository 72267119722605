import React, { Component } from 'react';
import CountUp from 'react-countup';


class AboutOne extends Component {
    render() {
        return (
            <section className="about-wrapper about-us-one section-padding">
                <div className="about-shape">
                    <img src={require ("../../assets/img/shape/circle1.png") } alt="donto" className="shape ab1" />
                    <img src={require ("../../assets/img/shape/bluef.png") } alt="donto" className="shape ab2" />
                    <img src={require ("../../assets/img/shape/dotmr.png") } alt="donto" className="shape ab3" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="about-us-one-left">
                                <img src={require ("../../assets/img/whatsinaname/AboutNewImage.png") } alt="donto" />
                                {/*<img src={require ("../../assets/img/about-banner1.png") } alt="donto" />
                                <img src={require ("../../assets/img/about-banner2.png") } alt="donto" className="about-animate" />*/}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="about-us-one-right mt-5 mt-lg-0 vAboutContent">
                                <div className="about-heading">
                                    <h1>About Sleep Therapeutics</h1>
                                </div>
                                <p>A first-of-its-kind sleep clinic, based in Hyderabad, for the twin Telugu states of Telangana and Andhra Pradesh. Also one of the best in India, with a multidisciplinary team, world-class sleep labs and comprehensive sleep care that caters to every age group, from the young to the senior citizens. Our holistic and integrated approach to sleep medicine, assisted by patient-friendly technology, makes us your ideal partner in treating sleep disorders.</p>
                                <p>The Breathe Clinic, our sister concern, focuses on diagnosing and treating respiratory disorders by adopting the same approach.</p>
                                {/*<a href="/Page/About" className="btn-link">About Us</a>
                                <div className="fun-fact-section ">
                                    <div className="single-funfact-one">
                                        < span > < CountUp
                                        end = {
                                            500
                                        }
                                        duration = {
                                            3.75
                                        }
                                        /></span >
                                        <p>Happy Patients</p>
                                    </div>
                                    <div className="single-funfact-one sp-fun">
                                        <span>
                                            <CountUp
                                end={88}
                                duration={2.75} />
                                </span>
                                        <p>Qualified Doctors</p>
                                    </div>
                                    <div className="single-funfact-one">
                                        < span > < CountUp
                                        end = {
                                            25
                                        }
                                        duration = {
                                            3.75
                                        }
                                        /></span >
                                        <p>Years Experience</p>
                                    </div>
                                    <div className="single-funfact-one sp-fun">
                                        <span><CountUp
                                end={55}
                                duration={3.75} /></span>
                                        <p>Dental Awards</p>
                                    </div>
                                    <span className="line" />
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AboutOne
