import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerVirtualTour from './BannerVirtualTour'
import SectionTitleOne from './SectionTitleOne'
import ContactAppointment from './ContactAppointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'

import { Helmet } from 'react-helmet';

class PartnerWithUs extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Treatment Centre In Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Enhance your sleep at the Best Sleep Centre in Hyderabad. Get personalized assessments, diagnostics, and treatments from our experts for better sleep.' />
                </Helmet>
                <BannerVirtualTour pageTitle='Virtual Tour' />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>Welcome to Sleep Therapeutics</h1>
                                    <p>Revolutionising sleep medicine in Telangana & Andhra Pradesh</p>
                                    <p className='vTakeTourHead'>Take a 360° view virtual tour of our facility.</p>
                                    <div className='vTour360Btn'>
                                        <a href="https://sleeptherapeutics.in/sleep-360/index.htm" target="_blank">START 360° TOUR</a>
                                    </div>
                                    <div className='vVirtualTourContent'>
                                        <p>Sleep Therapeutics is the first exclusive sleep medicine centre for the twin Telugu states of Telangana and Andhra Pradesh. Located in the heart of Hyderabad at Jubilee Hills, it’s easily accessible from anywhere.</p>
                                        <p>With our state-of-the-art sleep labs, a multidisciplinary team, and a holistic and integrated treatment approach, start saying goodbye to sleep disorders. Sleep Therapeutics specialises in Sleep Apnea and Insomnia treatment through the expert hands of Dr Harshini Errabelli, our Founder-Director and sleep specialist.</p>
                                        <p>We invite you to a 360-degree view virtual tour of our sleep centre, which showcases our facilities and services in Jubilee Hills.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-page-top-content-wrap">
                    <div className="container vVirtalSection">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className='row'>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/AdvancedEquipment.png")} alt="donto" />
                                            <p>Advanced<br/>Equipment</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/LeadingDoctors.png")} alt="donto" />
                                            <p>Leading &<br/>Experienced Doctors</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/PremiumSleep.png")} alt="donto" />
                                            <p>Premium Sleep<br/>Test Rooms</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/State-of-the-art.png")} alt="donto" />
                                            <p>State-of-the-art<br/>Labs</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/Multi-disciplinary.png")} alt="donto" />
                                            <p>Multi-disciplinary<br/>Team</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/HolisticTreatment.png")} alt="donto" />
                                            <p>Holistic Treatment<br/>Packages</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/AmpleParking.png")} alt="donto" />
                                            <p>Ample Parking<br/>Space Available</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/24x7Security.png")} alt="donto" />
                                            <p>24x7 Security<br/>Surveillance</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                                        <div className='vTourIconBlock'>
                                            <img src={require("../assets/img/whatsinaname/Icons/AlsoOpen.png")} alt="donto" />
                                            <p>Also open on<br/>Sundays (10 am - 2 pm)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </React.Fragment>
        )
    }
}

export default PartnerWithUs
