import React, { Component } from 'react'
import Slider from 'react-slick';

class NewsPublished extends Component {
    render() {

        const settings = {
            slidesToShow: 3,
            slidesToScroll: 4,
            dots: true,
            infinite: true,
            autoplay: false,
            speed: 4000,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]           
        };

        return (
            <section className="brand-logo-wrapper vVedioBlock">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12 text-center'>
                            <h1>Published in Newspapers</h1>
                        </div>
                        <div className="col-md-12">
                            <Slider className="brand-logo-carousel" {...settings}>
                                <div className="single-brand-logo videoSpace">
                                    <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper31.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper20.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                    {/*<img src={require("../assets/img/brand/2.png")} alt="donto" />*/}
                                    <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper21.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper22.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper23.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper24.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper25.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper26.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper27.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper28.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper29.jpg")} alt="donto" />
                                </div>
                                <div className="single-brand-logo videoSpace">
                                <img src={require("../assets/img/whatsinaname/gallery/innerpage/NewsPaper/paper30.jpg")} alt="donto" />
                                </div>
                            </Slider >
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default NewsPublished
